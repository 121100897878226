// src/App.js

import React, { useState } from 'react';
import './App.css';
import DataFetcher from './DataFetcher';
import Login from './components/login';



function App() {
  // Initialize isLoggedIn from localStorage
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem('isLoggedIn') === 'true';
  });

  const [loginMessage, setLoginMessage] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown visibility

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    sessionStorage.setItem('isLoggedIn', 'true'); // Save login status in local storage
    setLoginMessage('');
  };

  const handleLoginFailure = (message) => {
    setIsLoggedIn(false);
    sessionStorage.removeItem('isLoggedIn'); // Clear login status from local storage
    setLoginMessage(message);
  };


  return (
    <div className="App">
      <header className="App-header">
        {isLoggedIn ? (
          <DataFetcher />
        ) : (
          <>
            <Login onLoginSuccess={handleLoginSuccess} onLoginFailure={handleLoginFailure} />
            {loginMessage && <p>{loginMessage}</p>}
          </>
        )}
      </header>
    </div>
  );
}

export default App;

/*
import React from 'react';
import './App.css';
import DataFetcher from './DataFetcher';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <DataFetcher />
      </header>
    </div>
  );
}

export default App;

*/
