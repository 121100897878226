/*
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './DataFetcher.css';  // Import CSS file for styling

function DataFetcher() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get('http://attero-news.agger.in/api/data')
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  }, []);

  const handleImageError = (e) => {
    e.target.src = 'https://via.placeholder.com/150'; // Fallback image URL
    console.error('Image failed to load:', e.target.src);
  };

  const getImageUrl = (url) => {
    return `http://attero-news.agger.in/api/proxy-image?url=${encodeURIComponent(url)}`;
  };

  const formatDate = (dateString) => {
    // Adjust the date parsing according to your date format in the database
    const date = new Date(dateString);
    // Format options; adjust if needed
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return isNaN(date.getTime()) ? 'Invalid date' : date.toLocaleDateString(undefined, options);
  };


  return (
    <div className="card-container">
      {data.map((item, index) => (
        <div key={index} className="card">
          <a href={item.redirectedURL} className="card-link">
            <img 
              src={getImageUrl(item.Image)} 
              alt={item.Title} 
              className="card-image" 
              onError={handleImageError} 
            />
            <div className="card-content">
              <h2>{item.Title}</h2>
              <p>{item.description}</p>
              <p className="card-source" style={{color: "blue"}}>{item.Source}</p>
              <p className="card-date">{formatDate(item.Date)}</p>  
            </div>
          </a>
        </div>
      ))}
    </div>
  );
}

export default DataFetcher;
*/


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './DataFetcher.css';  // Import CSS file for styling

function DataFetcher() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('http://attero-news.agger.in/api/data')
      .then(response => {
        setArticles(response.data);
        setError(null);
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
        setError('Error fetching data. Please try again later.');
      })
      .finally(() => setLoading(false));
  }, []);

  const handleImageError = (e) => {
    e.target.src = 'https://via.placeholder.com/150'; // Fallback image URL
    console.error('Image failed to load:', e.target.src);
  };

  const getImageUrl = (image) => {
    let filename;
    
    if (typeof image === 'object' && image !== null) {
      filename = image.filename; // Extract filename from object
      if (typeof filename !== 'string') {
        console.error('Filename property is not a string:', filename);
        return 'https://via.placeholder.com/150'; // Fallback image
      }
    } else if (typeof image === 'string') {
      filename = image; // Use the string directly
    } else {
      console.error('Unexpected image format:', image);
      return 'https://via.placeholder.com/150'; // Fallback image
    }
  
    return `http://attero-news.agger.in/api/local-image/${filename}`;
  };
  

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return isNaN(date.getTime()) ? 'Invalid date' : date.toLocaleDateString(undefined, options);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
<div className="card-container">
  {articles.map((item, index) => (
    <div key={index} className="card">
      <a href={item.redirectedURL} className="card-link">
        <img 
          src={getImageUrl(item.image)} 
          alt={item.title || 'Image'}
          className="card-image"
          onError={handleImageError} 
        />
        <div className="card-content">
          <h2>{item.Title}</h2>
          <p>{item.Source}</p>
          <p className="card-date">{formatDate(item.Date)}</p>
        </div>
      </a>
      {item.URL && (
        <p className="card-source" style={{ color: 'blue' }}>
          <a href={item.URL} target="_blank" rel="noopener noreferrer">{item.Source}</a>
        </p>
      )}
    </div>
  ))}
</div>

  );
}

export default DataFetcher;

